import React, { useState, useEffect } from 'react'
import './Modal.css'
import { toast } from 'react-toastify'
import axios from 'axios'
import { stylists, services } from '../Constants'
export default function Modal({ setOpen }) {

  const [step, setStep] = useState(1)
  const [category, setCategory] = useState("")
  const [categories, setCategories] = useState([])
  const [availableServices, setAvailableServices] = useState([])
  const [staff, setStaff] = useState([])
  const [selectedStaff, setSelectedStaff] = useState("")
  const [service, setService] = useState("")
  const [date, setDate] = useState("")
  const [time, setTime] = useState("")
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [number, setNumber] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [sent, setSent] = useState(false)
  const [marketing, setMarketing] = useState(true)

    const handleContainerClick = (e) => {
        e.stopPropagation(); // Prevents click event from bubbling up to the modal div
      };

  const handleSubmit = () => {
    if(!service || !date || !time || !name || !email || !number){
      return toast.error("Please add all fields")
    }
    let payload = {
      service, date, time, name, email, number, staff: selectedStaff, marketing
    }
    setSubmitting(true)
    axios.post(`${process.env.REACT_APP_API}/artdotsalon/booking`, payload)
    .then(() => {
      setSent(true)
    })
    .catch(() => {
      toast.error("Error with booking")
    })
    .finally(() => {
      setSubmitting(false)
    })

  }

  useEffect(() => {
    const getCategories = () => {
      let arr = [];
      Object.keys(services).forEach((category) => {
        arr.push(capitaliseWords(category.replace(/_/g, " ")));
      });
      setCategories(arr);
    };
  
    getCategories();
  }, [services]);

  function capitaliseWords(text) {
    return text
      .toLowerCase()
      .split(" ")
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  useEffect(() => {
    //Rest the service
    setService("")
    if (category) {
      const selectedCategory = Object.entries(services).find(
        ([key]) => capitaliseWords(key.replace(/_/g, " ")) === category
      );
      setAvailableServices(selectedCategory ? selectedCategory[1] : []);
    }
  }, [category]);

  useEffect(() => {
    if (service) {
      const filteredStaff = Object.keys(stylists).filter((stylist) => {
        return stylists[stylist].some((serviceGroup) => {
          // Check if the stylist's services include the selected service keyword
          return (
            serviceGroup.service.toLowerCase().includes(service.toLowerCase()) ||
            serviceGroup.subService.some((sub) =>
              sub.name.toLowerCase().includes(service.toLowerCase())
            )
          );
        });
      });
      setStaff(filteredStaff);
    } else {
      setStaff([]);
    }
  }, [service]);
      
  return (
    <div className='modal' onClick={() => setOpen(false)}>
      <div className='modal-container' onClick={handleContainerClick}>
        {sent ?
        <div style={{display:'flex', justifyContent:'center', alignItems: 'center', flexDirection:'column', paddingTop: 50}}>
          <h3 style={{fontSize: 36, color: '#9D7A68'}}>Successfully sent</h3>
          <p style={{marginTop: 30}}>A member of our team will be in touch shortly to confirm your booking. </p>
          <button onClick={() => setOpen(false)} style={{marginTop: 30}} className='solid-black-btn'>Close</button>
        </div>
        :
        <>
        {step === 1 && 
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h3 style={{ fontSize: 36 }}>Step One:</h3>
            <h3 style={{ color: '#9D7A68', fontSize: 36 }}>Choose Your Service</h3>

            <label style={{ marginTop: 30 }}>Select a service category</label>
            <select 
              className='booking-select'
              value={category}
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="" disabled>Select a category</option>
              {categories.map((category) => (
                      <option 
                        key={category}
                        value={category}
                      >
                        {category == 'braids_locs' ? 'Braid & Locs' : capitaliseWords(category)}
                      </option>
              ))}
            </select>
            { availableServices.length > 0 && 
            <>
                <label style={{ marginTop: 10 }}>Select from our range of services</label>
                    <select
                      className='booking-select'
                      value={service}
                      onChange={(e) => setService(e.target.value)}
                    >
                      <option value="" disabled>Select a service</option>
                      {availableServices.map((serviceGroup) => (
                        <optgroup key={serviceGroup.service} label={serviceGroup.service}>
                          {serviceGroup.subService.map((subService) => (
                            <option
                              key={subService.name}
                              value={subService.name}
                            >
                              {subService.name}
                            </option>
                          ))}
                        </optgroup>
                      ))}
                    </select>
              </>
            }

            { staff.length > 0 && 
            <>
                <label style={{ marginTop: 10 }}>Select a member of staff</label>
                    <select
                      className='booking-select'
                      value={selectedStaff}
                      onChange={(e) => setSelectedStaff(e.target.value)}
                    >
                      <option value="" disabled>Select staff</option>
                      <option value="I don't mind" >I don't mind</option>

                      {staff.map((member) => (
                            <option
                              key={member}
                              value={member}
                            >
                              {member}
                            </option>
                          ))}
                    </select>
              </>
            }
          </div>
        }

        {step === 2 && 
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h3 style={{ fontSize: 36 }}>Step Two:</h3>
            <h3 style={{ color: '#9D7A68', fontSize: 36 }}>Choose Date & Time</h3>
            <label style={{ marginTop: 30 }}>Select Date</label>
            <input 
              className='booking-select' 
              type='date' 
              value={date} 
              onChange={(e) => setDate(e.target.value)}
            />
            <label style={{ marginTop: 10 }}>Select Time</label>
            <input 
              className='booking-select' 
              type='time'  
              value={time} 
              onChange={(e) => setTime(e.target.value)}
              min="08:00" 
              max="22:00" 
            />
          </div>
        }

        {step === 3 && 
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <h3 style={{ fontSize: 36 }}>Step Three:</h3>
            <h3 style={{ color: '#9D7A68', fontSize: 36 }}>Add Your Details</h3>
            <label style={{ marginTop: 30 }}>Full Name</label>
            <input 
              className='booking-select' 
              type='text'  
              value={name} 
              onChange={(e) => setName(e.target.value)} 
            />
            <label style={{ marginTop: 10 }}>Phone Number</label>
            <input 
              className='booking-select' 
              type='text' 
              value={number} 
              onChange={(e) => setNumber(e.target.value)} 
            />
            <label style={{ marginTop: 10 }}>Email</label>
            <input 
              className='booking-select' 
              type='text' 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
            />
            <div style={{display:'flex', marginBottom: 10}}>
              <input type='checkbox' checked={marketing} onChange={() => setMarketing(prev => !prev)}/>
              <p style={{ color: '#313131', fontSize: 12, marginLeft: 10 }}>I would like to sign up for special offers and marketing</p>
            </div>

            <p style={{ color: '#9D7A68', fontSize: 12 }}>
              Your booking isn’t confirmed until a team member contacts you.
            </p>
          </div>
        }

        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: 30 }}>
          {step < 2 
            ? <button className='black-btn' onClick={() => setOpen(false)}>Cancel</button> 
            : <button className='black-btn' onClick={() => setStep(prev => prev - 1)}>Back</button>}
          {step === 3 
            ? <button className='solid-black-btn' onClick={handleSubmit} disabled={submitting}>Confirm</button> 
            : <button className='solid-black-btn' onClick={() => setStep(prev => prev + 1)}>Next</button>}
        </div>
        </>
        }
        
      </div>
    </div>
  );
}

