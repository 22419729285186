import React from 'react'
import './Navbar.css'
import logo from '../Assets/images/artdotsalon.png'

export default function Navbar({ setOpen }) {

  const handleClickScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <header>
        <img id='nav-logo' src={logo}/>

        <nav className='mobile-remove'>
            <a onClick={() => handleClickScroll('about')}>about</a>
            <a onClick={() => handleClickScroll('services')}>services</a>
            <a onClick={() => handleClickScroll('team')}>the team</a>
            <a onClick={() => handleClickScroll('contact')}>contact</a>
            <a>
                <button className='white-btn' onClick={ () => setOpen(true)}>
                    Book Now
                </button>
            </a>
        </nav>

        <button className='white-btn mobile' onClick={ () => setOpen(true)}>
            Book Now
        </button>
    </header>
  )
}
