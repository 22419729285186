import { useState } from 'react';
import './App.css';
import About from './Components/About';
import Hero from './Components/Hero';
import Navbar from './Components/Navbar';
import Services from './Components/Services';
import Team from './Components/Team';
import Contact from './Components/Contact';
import Salon from './Components/Salon';
import Footer from './Components/Footer';
import Modal from './Components/Modal';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';

function App() {

  const [ open, setOpen] = useState(false)
  return (
    <div className="App">
      <ToastContainer position='top-center' />
      {open && <Modal setOpen={setOpen}/> }
      <Navbar setOpen={setOpen}/>
      <Hero setOpen={setOpen}/>
      <About setOpen={setOpen}/>
      <Services setOpen={setOpen}/>
      <Team />
      <Contact />
      <Salon />
      <Footer />
    </div>
  );
}

export default App;
